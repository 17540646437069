var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"baccarat-live"},[_c('b-form-group',{staticClass:"mb-0"},[_c('b-form-radio-group',{staticClass:"channel-switch",attrs:{"id":"btn-radios-1","options":_vm.channelOptions,"name":"radios-btn-default","buttons":"","button-variant":"light","disabled":_vm.isStart},model:{value:(_vm.channel),callback:function ($$v) {_vm.channel=$$v},expression:"channel"}})],1),_c('b-card',{staticClass:"mb-0",attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.channelItem.text)+" | "),(_vm.data.channelId > 2)?_c('span',[_vm._v(" Live "+_vm._s(_vm.data.liveNo)+" | ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("general.matchWithNumber", { fight: _vm.data.no }))+" "),(_vm.data.bettingStatus)?_c('span',{class:{
            'text-draw': _vm.data.bettingStatus == 2,
            'text-suspend':
              _vm.data.bettingStatus == 4 || _vm.data.bettingStatus == 1,
            'text-meron': _vm.data.bettingStatus == 3,
          }},[_vm._v(" | "+_vm._s(_vm.$t(("bettingStatus." + (_vm.data.bettingStatus))))+" ")]):_vm._e(),(_vm.data.openSeconds)?_c('span',[_vm._v(" ("+_vm._s(_vm.data.openSeconds)+") ")]):_vm._e()])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-input',{ref:"codeInput",attrs:{"disabled":!_vm.isStart,"name":"code"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getCode.apply(null, arguments)},"blur":_vm.handleBlur},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),(_vm.loading)?_c('h3',[_vm._v(_vm._s(_vm.$t("field.loading")))]):_vm._e()],1),_c('b-col',{attrs:{"cols":"4"}},[(!_vm.isStart)?_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":_vm.startGetResult}},[_vm._v(" "+_vm._s(_vm.$t("button.start"))+" ")]):_c('b-button',{attrs:{"variant":"danger","block":""},on:{"click":_vm.stopGetResult}},[_vm._v(" "+_vm._s(_vm.$t("button.stop"))+" ")])],1)],1),_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl']),expression:"['ctrl']"}],on:{"shortkey":function($event){return _vm.calculateResult()}}}),_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['tab']),expression:"['tab']"}],on:{"shortkey":function($event){return _vm.nextMatch()}}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.errorMessage)?_c('h1',{staticClass:"big-font mb-0 error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])],1),_c('b-card-body',{staticClass:"text-center min-height-200 baccarat"},[_c('b-row',{staticClass:"baccarat-result"},[_c('b-col',{staticClass:"px-0",class:{
            player: _vm.result == 2,
          },attrs:{"md":"4","lg":"3","xl":"2"}},[_c('h1',{staticClass:"text-wala big-font"},[_vm._v(" "+_vm._s(_vm.$t("field.player"))+" ("+_vm._s(_vm.getPlayerResult())+") ")]),_c('div',{staticClass:"baccarat-result-player"},[_c('b-img',{staticClass:"result result-1",attrs:{"src":("https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/" + (_vm.data.agBaccaPlayerCardOne ? _vm.data.agBaccaPlayerCardOne : 'back') + ".png")}}),_c('b-img',{staticClass:"result result-2",attrs:{"src":("https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/" + (_vm.data.agBaccaPlayerCardTwo ? _vm.data.agBaccaPlayerCardTwo : 'back') + ".png")}}),(_vm.data.agBaccaPlayerCardThree)?_c('b-img',{staticClass:"result result-3",attrs:{"src":("https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/" + (_vm.data.agBaccaPlayerCardThree) + ".png")}}):_vm._e()],1)]),_c('b-col',{class:{
            tie: _vm.result == 3,
          },attrs:{"md":"4","lg":"6","xl":"8"}},[(_vm.data.resultChange == 1)?_c('h1',{staticClass:"font-big text-wala"},[_vm._v(" "+_vm._s(this.$t("field.change"))+" ")]):(_vm.data.resultChange == 2)?_c('h1',{staticClass:"font-big text-meron"},[_vm._v(" "+_vm._s(this.$t("field.notChange"))+" ")]):_vm._e()]),_c('b-col',{staticClass:"px-0",class:{
            banker: _vm.result == 1,
          },attrs:{"md":"4","lg":"3","xl":"2"}},[_c('h1',{staticClass:"text-meron big-font"},[_vm._v(" "+_vm._s(_vm.$t("field.banker"))+" ("+_vm._s(_vm.getBankerResult())+") ")]),_c('div',{staticClass:"baccarat-result-banker"},[_c('b-img',{staticClass:"result result-1",attrs:{"src":("https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/" + (_vm.data.agBaccaBankerCardOne ? _vm.data.agBaccaBankerCardOne : 'back') + ".png")}}),_c('b-img',{staticClass:"result result-2",attrs:{"src":("https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/" + (_vm.data.agBaccaBankerCardTwo ? _vm.data.agBaccaBankerCardTwo : 'back') + ".png")}}),(_vm.data.agBaccaBankerCardThree)?_c('b-img',{staticClass:"result result-3",attrs:{"src":("https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/" + (_vm.data.agBaccaBankerCardThree) + ".png")}}):_vm._e()],1)])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"pr-lg-1",attrs:{"lg":"6"}},[_c('fight-result',{ref:"fightResult",attrs:{"channel":_vm.channel,"channel-item":_vm.channelItem}})],1),_c('b-col',{staticClass:"pl-lg-1",attrs:{"lg":"6"}},[_c('betting-result',{staticClass:"bc",attrs:{"results":_vm.results}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }