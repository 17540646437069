<template>
  <div class="baccarat-live">
    <b-form-group class="mb-0">
      <b-form-radio-group
        id="btn-radios-1"
        v-model="channel"
        :options="channelOptions"
        name="radios-btn-default"
        buttons
        button-variant="light"
        class="channel-switch"
        :disabled="isStart"
      ></b-form-radio-group>
    </b-form-group>

    <b-card header-tag="header" class="mb-0">
      <template #header>
        <h2 class="mb-0">
          {{ channelItem.text }} |
          <span v-if="data.channelId > 2"> Live {{ data.liveNo }} | </span>
          {{ $t("general.matchWithNumber", { fight: data.no }) }}
          <span
            v-if="data.bettingStatus"
            :class="{
              'text-draw': data.bettingStatus == 2,
              'text-suspend':
                data.bettingStatus == 4 || data.bettingStatus == 1,
              'text-meron': data.bettingStatus == 3,
            }"
          >
            | {{ $t(`bettingStatus.${data.bettingStatus}`) }}
          </span>
          <span v-if="data.openSeconds"> ({{ data.openSeconds }}) </span>
        </h2>
      </template>

      <b-row>
        <b-col cols="8">
          <b-form-input
            :disabled="!isStart"
            ref="codeInput"
            name="code"
            v-model="code"
            @keydown.enter="getCode"
            @blur="handleBlur"
          />
          <h3 v-if="loading">{{ $t("field.loading") }}</h3>
        </b-col>
        <b-col cols="4">
          <b-button
            variant="primary"
            block
            v-if="!isStart"
            @click="startGetResult"
          >
            {{ $t("button.start") }}
          </b-button>
          <b-button variant="danger" block v-else @click="stopGetResult">
            {{ $t("button.stop") }}
          </b-button>
        </b-col>
      </b-row>
      <div v-shortkey="['ctrl']" @shortkey="calculateResult()"></div>
      <div v-shortkey="['tab']" @shortkey="nextMatch()"></div>
      <b-row>
        <b-col cols="12">
          <h1 class="big-font mb-0 error-message" v-if="errorMessage">
            {{ errorMessage }}
          </h1>
        </b-col>
      </b-row>
      <b-card-body class="text-center min-height-200 baccarat">
        <b-row class="baccarat-result">
          <b-col
            md="4"
            lg="3"
            xl="2"
            class="px-0"
            :class="{
              player: result == 2,
            }"
          >
            <h1 class="text-wala big-font">
              {{ $t("field.player") }} ({{ getPlayerResult() }})
            </h1>
            <div class="baccarat-result-player">
              <b-img
                class="result result-1"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.agBaccaPlayerCardOne ? data.agBaccaPlayerCardOne : 'back'
                }.png`"
              />
              <b-img
                class="result result-2"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.agBaccaPlayerCardTwo ? data.agBaccaPlayerCardTwo : 'back'
                }.png`"
              />
              <b-img
                class="result result-3"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${data.agBaccaPlayerCardThree}.png`"
                v-if="data.agBaccaPlayerCardThree"
              />
            </div>
          </b-col>
          <b-col
            md="4"
            lg="6"
            xl="8"
            :class="{
              tie: result == 3,
            }"
          >
            <h1 class="font-big text-wala" v-if="data.resultChange == 1">
              {{ this.$t("field.change") }}
            </h1>
            <h1 class="font-big text-meron" v-else-if="data.resultChange == 2">
              {{ this.$t("field.notChange") }}
            </h1>
          </b-col>
          <b-col
            md="4"
            lg="3"
            xl="2"
            class="px-0"
            :class="{
              banker: result == 1,
            }"
          >
            <h1 class="text-meron big-font">
              {{ $t("field.banker") }} ({{ getBankerResult() }})
            </h1>
            <div class="baccarat-result-banker">
              <b-img
                class="result result-1"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.agBaccaBankerCardOne ? data.agBaccaBankerCardOne : 'back'
                }.png`"
              />
              <b-img
                class="result result-2"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.agBaccaBankerCardTwo ? data.agBaccaBankerCardTwo : 'back'
                }.png`"
              />
              <b-img
                class="result result-3"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${data.agBaccaBankerCardThree}.png`"
                v-if="data.agBaccaBankerCardThree"
              />
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-row>
      <b-col lg="6" class="pr-lg-1">
        <fight-result
          :channel="channel"
          :channel-item="channelItem"
          ref="fightResult"
        ></fight-result>
      </b-col>
      <b-col lg="6" class="pl-lg-1">
        <betting-result class="bc" :results="results"></betting-result>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormRadioGroup,
  BCard,
  BCardBody,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BImg,
} from "bootstrap-vue";
import db from "@/libs/firestore-db";
import { camelizeKeys } from "humps";
import Repository from "@/repositories/RepositoryFactory";
import FightResult from "@/views/liveBetting/liveStream/FightResult.vue";
import BettingResult from "@/views/liveBetting/liveStream/BettingResult.vue";

const FightSetupRepository = Repository.get("fightSetup");

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BCard,
    BCardBody,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BImg,
    FightResult,
    BettingResult,
  },
  computed: {
    channelOptions() {
      let userData = this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
      let channelOptions = [];
      userData.channels.forEach((row) => {
        if (row.channelType == 2) {
          channelOptions.push({
            text: row.channelName,
            value: row.id,
            type: row.channelType,
          });
        }
      });

      if (channelOptions.length && !this.channel) {
        let activeChannel = 0;
        this.channel = channelOptions[activeChannel].value;
      }

      return channelOptions;
    },
    channelItem: function () {
      const index = this.channelOptions.findIndex((row) => {
        return row.value == this.channel;
      });
      if (index != -1) {
        return this.channelOptions[index];
      }
      return {};
    },
  },
  watch: {
    channel() {
      this.updateQuerySting();
      this.listenMatch();
      this.listenResult();
      this.listenResultBanker();
    },
    resultNo(value) {
      if (value) {
        this.listMatchResult();
      }
    },
  },
  data() {
    return {
      loading: false,
      isStart: false,
      channel: Number(this.$route.query.channel) || null,
      data: {
        no: null,
        resultChange: 0,
        agBaccaBankerCardOne: null,
        agBaccaBankerCardTwo: null,
        agBaccaBankerCardThree: null,
        agBaccaPlayerCardOne: null,
        agBaccaPlayerCardTwo: null,
        agBaccaPlayerCardThree: null,
      },
      code: "",
      errorMessage: "",

      result: null,

      listenFbMatch: null,

      listenFbResult: null,
      resultNo: null,

      results: [],

      listenFbResultBanker: null,
    };
  },
  beforeRouteLeave(to, from, next) {
    // Check your condition here
    if (!this.isStart) {
      // Allow the route change
      next();
    } else {
      // Prevent the route change
      next(false);
    }
  },
  created() {
    this.listenMatch();
    this.listenResult();
    this.listenResultBanker();
  },
  methods: {
    getBankerResult() {
      let agBaccaBankerCardOne = this.getNumber(this.data.agBaccaBankerCardOne);
      let agBaccaBankerCardTwo = this.getNumber(this.data.agBaccaBankerCardTwo);
      let agBaccaBankerCardThree = this.getNumber(this.data.agBaccaBankerCardThree);

      let result =
        agBaccaBankerCardOne + agBaccaBankerCardTwo + agBaccaBankerCardThree;
      return this.getResult(result);
    },
    getPlayerResult() {
      let agBaccaPlayerCardOne = this.getNumber(this.data.agBaccaPlayerCardOne);
      let agBaccaPlayerCardTwo = this.getNumber(this.data.agBaccaPlayerCardTwo);
      let agBaccaPlayerCardThree = this.getNumber(this.data.agBaccaPlayerCardThree);

      let result =
        agBaccaPlayerCardOne + agBaccaPlayerCardTwo + agBaccaPlayerCardThree;
      return this.getResult(result);
    },
    getNumber(value) {
      if (value) {
        let digit = value.slice(1, 3);
        digit = parseInt(digit, 10);
        if (digit > 10) {
          return 10;
        }

        return digit;
      }

      return 0;
    },
    getResult(number) {
      return number % 10;
    },
    nextMatch() {
      if (this.isStart && !this.loading) {
        console.log("Next match");
        this.loading = true;
        this.errorMessage = "";
        FightSetupRepository.openNextMatch({
          channelId: this.channel,
        })
          .then((response) => {
            console.log("Finish Next match");
            this.loading = false;
          })
          .catch((error) => {
            console.log("Error Next match");
            this.loading = false;
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    calculateResult() {
      if (this.isStart && !this.loading) {
        console.log("Calculate result");
        this.loading = true;
        this.errorMessage = "";
        FightSetupRepository.calResult({
          channelId: this.channel,
        })
          .then((response) => {
            console.log("Finish Calculate result");
            const data = response?.data?.data;
            if (data?.message) {
              this.errorMessage = data.message;
              setTimeout(() => {
                this.errorMessage = "";
              }, 5000);
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log("Error Calculate result");
            this.loading = false;
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    getCode() {
      if (this.isStart && this.code && !this.loading) {
        this.loading = true;
        this.errorMessage = "";
        FightSetupRepository.submitResult({
          code: this.code,
          channelId: this.channel,
        })
          .then((response) => {
            this.$nextTick(() => {
              this.code = "";
              this.loading = false;
            });
          })
          .catch((error) => {
            this.loading = false;
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    handleBlur() {
      this.$nextTick(() => {
        if (this.isStart) {
          this.code = "";
          this.$refs.codeInput.focus();
        }
      });
    },
    startGetResult() {
      this.isStart = true;
      setTimeout(() => {
        this.$refs.codeInput.focus();
      }, 500);
    },
    stopGetResult() {
      this.isStart = false;
      this.$refs.codeInput.blur();
    },
    listenMatch() {
      if (!this.channel && this.channelOptions.length > 0) {
        this.channel = this.channelOptions[0].id;
      }
      this.data.resultChange = 0;
      this.listenFbMatch && this.listenFbMatch();
      this.listenFbMatch = db
        .collection("fight_match")
        .doc(`match_${this.channel}`)
        .onSnapshot((response) => {
          let data = response.get("data");
          if (data) {
            data = camelizeKeys(JSON.parse(data));
            this.data = {
              ...data,
            };
          }
        });
    },
    updateQuerySting() {
      this.$router.replace({
        query: {
          channel: this.channel,
        },
      });
    },

    listenResult() {
      this.listenFbResult && this.listenFbResult();
      this.listenFbResult = db
        .collection("release")
        .doc(`release_${this.channel}`)
        .onSnapshot((response) => {
          this.resultNo = response.get("result_release_no");
        });
    },
    listMatchResult() {
      const data = {
        channelId: this.channel,
      };
      FightSetupRepository.listFightResult(data).then((response) => {
        if (response?.data?.data) {
          this.results = response.data.data;
          this.$refs.fightResult.mapResult(this.results);
        }
      });
    },

    listenResultBanker() {
      this.listenFbResultBanker && this.listenFbResultBanker();
      this.listenFbResultBanker = db
        .collection("fight_result")
        .doc(`result_${this.channel}`)
        .onSnapshot((response) => {
          this.result = response.get("result");
        });
    },
  },
  setup() {},
};
</script>