<template>
  <div ref="result" v-if="!loading">
    <b-card
      :key="`result-${channel}`"
      :class="{
        'result-report-card': channelItem.type == 2,
        'py-2': channelItem.type == 2,
      }"
    >
      <b-row>
        <b-col ref="fightResult">
          <div class="result-container">
            <div
              v-for="(row, rIndex) in results"
              :key="`row-${rIndex}`"
              class="result-row"
            >
              <div
                v-for="(result, index) in row"
                :key="`result-${index}`"
                class="result-col"
              >
                <b-img-lazy
                  :src="result.image"
                  class="fight-result-img"
                  v-if="result.image && channelItem.type != 2"
                ></b-img-lazy>
                <div
                  class="result-summary"
                  v-if="result.image && channelItem.type == 2"
                >
                  <span class="reult-number" v-if="result.result == 'wala'">
                    P
                  </span>
                  <span class="reult-number" v-if="result.result == 'meron'">
                    B
                  </span>
                  <span class="reult-number" v-if="result.result == 'draw'">
                    T
                  </span>
                  <b-img-lazy
                    :src="result.image"
                    class="fight-result-img"
                  ></b-img-lazy>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BImgLazy,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTr,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BImgLazy,
  },
  props: {
    channel: {
      type: Number,
      default: 1,
    },
    channelItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      results: [],
    };
  },
  methods: {
    mapResult(data) {
      let results = [];
      let imagePath = this.channelItem.type != 2 ? "flight" : "baccarat";
      let index = 0;
      let previousWinner = "";
      let cols = 6;
      let sameMatchCount = 0;
      let lastRow = 7;
      data.forEach((item, i) => {
        if (item.result) {
          if (!results[index]) results[index] = [];
          if (
            previousWinner === "" ||
            previousWinner === item.result ||
            item.result === "draw" ||
            item.result === "suspend" ||
            item.result === "cancel"
          ) {
            if (sameMatchCount === cols) {
              index++;
              sameMatchCount--;
              if (!results[index]) results[index] = [];
              for (let s = 0; s < sameMatchCount; s++) {
                results[index][s] = {
                  result: "",
                  image: "",
                };
              }
            }
            if (lastRow === sameMatchCount) {
              index++;
              lastRow = sameMatchCount;
              sameMatchCount--;
            } else {
              lastRow = 7;
              if (results[index][sameMatchCount]) {
                if (results[index][sameMatchCount].result !== "") {
                  index++;
                  lastRow = sameMatchCount;
                  sameMatchCount--;
                }
              }
            }

            if (!results[index]) {
              results[index] = [];
            }
            for (let s = 0; s < cols; s++) {
              if (!results[index][s]) {
                results[index][s] = {
                  result: "",
                  image: "",
                };
              }
            }

            results[index][sameMatchCount] = {
              result: item.result,
              image: require(`@/assets/images/${imagePath}/${item.result}.svg`),
            };
            sameMatchCount++;
          } else if (previousWinner !== item.result) {
            lastRow = 7;
            if (sameMatchCount < cols) {
              for (let s = sameMatchCount; s < 5; s++) {
                if (!results[index][s]) {
                  results[index][s] = {
                    result: "",
                    image: "",
                  };
                }
              }
            }
            sameMatchCount = 0;
            index++;
            for (let c = 0; c < index; c++) {
              if (results[c][0].result === "") {
                index = c;
                break;
              }
            }

            if (!results[index]) results[index] = [];
            results[index][sameMatchCount] = {
              result: item.result,
              image: require(`@/assets/images/${imagePath}/${item.result}.svg`),
            };
            sameMatchCount++;
          }
          if (item.result === "meron" || item.result === "wala")
            previousWinner = item.result;
        }
      });
      if (results.length > 0) {
        let i = results.length - 1;
        for (let c = 0; c < cols; c++) {
          if (!results[i][c]) {
            results[i][c] = {
              result: "",
              image: "",
            };
          }
        }
      }
      this.results = [...results];
      if (data.length) {
        this.loading = false;
        this.scrollEnd();
      }
    },
    scrollEnd() {
      const el = this.$refs.fightResult;
      if (el) {
        setTimeout(() => {
          el.firstElementChild.scrollLeft = el.firstElementChild.scrollWidth;
        }, 300);
      }
    },
  },
  watch: {
    results() {
      this.$emit("items", this.items);
    },
  },
};
</script>
