<template>
  <b-card v-if="results.length">
    <b-row>
      <b-col>
        <b-card class="result-report-card">
          <b-row>
            <b-col ref="bettingResult">
              <div class="result-container">
                <div
                  v-for="(row, rIndex) in items"
                  :key="`row-${rIndex}`"
                  class="result-row"
                >
                  <div
                    v-for="(result, index) in row"
                    :key="`result-${index}`"
                    class="result-col"
                  >
                    <div class="result-summary">
                      <span class="reult-number">{{ result.no }}</span>
                      <b-img-lazy
                        :src="result.img"
                        class="fight-result-img"
                      ></b-img-lazy>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BImgLazy, BRow } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImgLazy,
  },
  props: {
    results: {},
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {},
  watch: {
    results() {
      this.reSortRow();
    },
  },
  methods: {
    reSortRow() {
      this.items = [];
      if (this.results) {
        let ri = 0;
        let index = 0;
        this.results.forEach((x, i) => {
          if (x.result) {
            if (!this.items[ri]) this.items[ri] = [];
            x.img = require(`@/assets/images/result/${x.result}.svg`);
            this.items[ri].push(x);
            if (index === 4) {
              ri++;
              index = 0;
            } else index++;
          }
        });
        this.scrollEnd();
      } else {
        this.item = [];
      }
    },
    scrollEnd() {
      const el = this.$refs.bettingResult;
      if (el) {
        setTimeout(() => {
          el.firstElementChild.scrollLeft = el.firstElementChild.scrollWidth;
        }, 300);
      }
    },
  },
};
</script>
